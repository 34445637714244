import React from "react";
import useTranslation from "next-translate/useTranslation";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import classNames from "lib/classNames";
import IconFacebook from "components/Icons/IconFacebook";

export default function Sidebar({ mainRef, searchStatus, searchCallback }) {
  const { t } = useTranslation("common");

  return (
    <div className="absolute hidden lg:flex flex-col justify-end xl:justify-between inset-y-0 right-0 bg-primary text-white border-l border-primary mt-[116px] xl:mt-0 z-10">
      <button
        className={classNames(
          "hidden xl:block p-8 hover:bg-primary-dark transition-colors",
          searchStatus && "bg-primary-dark"
        )}
        onClick={searchCallback}
      >
        <SearchIcon className="h-4 w-4" />
        <span className="mt-4 text-sm font-important font-medium uppercase tracking-wider wm-vertical-lr rotate-180">
          {t("search.title")}
        </span>
      </button>
      {mainRef && (
        <button
          className="group p-8 transition-colors hover:bg-primary-dark"
          onClick={() =>
            mainRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        >
          <ChevronDoubleDownIcon className="h-5 w-5 animate-pulse group-hover:animate-none" />
          <span className="sr-only">Accéder au contenu</span>
        </button>
      )}
    </div>
  );
}

export function SidebarFooter({ topRef }) {
  const { t } = useTranslation("common");

  return (
    <div className="lg:absolute flex flex-row-reverse lg:flex-col justify-between inset-y-0 right-0  bg-primary text-white border-l border-primary">
      {topRef && (
        <button
          className="group p-8 transition-colors hover:bg-primary-dark"
          onClick={() =>
            topRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        >
          <ChevronDoubleUpIcon className="h-5 w-5 animate-pulse group-hover:animate-none" />
          <span className="sr-only">Haut de page</span>
        </button>
      )}

      {/* <a 
        href={t('facebook.url')} 
        className="flex flex-row-reverse lg:flex-col items-center gap-4 p-8 hover:bg-primary-dark transition-colors" 
        target="_blank"
      >
        <span className="sr-only sm:not-sr-only mt-4 text-sm font-important font-medium uppercase tracking-wider lg:wm-vertical-lr">
          {t('facebook.title')}
        </span>
        <span className="block p-1 bg-white rounded-full text-primary">
          <IconFacebook className="h-3 w-3" aria-hidden="true" />
        </span>
      </a> */}
    </div>
  );
}
