import { MenuAlt3Icon, XIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";
import React, { Fragment, useEffect, useState } from "react";
import MainMenu from "components/Menu/MainMenu";
import SocialNetworksMenu from "components/Menu/SocialNetworksMenu";
import { Dialog, Transition } from "@headlessui/react";

const NAVBAR_BREAKPOINT = 1280;

export default function Navbar({ globals }) {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(() => {
    return (
      typeof window !== "undefined" && window.innerWidth >= NAVBAR_BREAKPOINT
    );
  });

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= NAVBAR_BREAKPOINT);
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
    }
  }, [setIsDesktop]);

  useEffect(() => {
    if (isDesktop) {
      setIsOpen(false);
    }
  }, [isDesktop, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.remove("overflow-y-visible");
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
      document.body.classList.add("overflow-y-visible");
    }
  }, [isOpen]);

  return isDesktop ? (
    <nav className="flex divide-x h-full">
      {globals?.menus?.main && <MainMenu menu={globals.menus.main} depth={2} />}

      {globals?.menus?.socialNetworks && (
        <SocialNetworksMenu menu={globals.menus.socialNetworks} />
      )}
    </nav>
  ) : (
    <>
      <button
        className="group absolute inset-y-0 right-0 flex flex-col items-center justify-center gap-2 bg-primary-dark text-white px-6 py-8 w-[85px] border-l border-primary"
        onClick={() => setIsOpen(true)}
      >
        <MenuAlt3Icon
          className="h-5 w-5 group-hover:animate-pulse"
          aria-hidden="true"
        />
        <span className="block sr-only md:not-sr-only uppercase font-important font-medium text-xs tracking-wider">
          Menu
        </span>
      </button>

      <Transition as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black/70 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            className="absolute inset-y-0 right-0 left-0 py-28 px-4 bg-primary-dark h-full md:left-1/4 md:px-24"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            {globals?.menus?.main && (
              <MainMenu
                menu={globals.menus.main}
                closeCallback={() => setIsOpen(false)}
              />
            )}

            {globals?.menus?.socialNetworks && (
              <SocialNetworksMenu menu={globals.menus.socialNetworks} />
            )}

            <button
              className="group absolute top-0 right-0 flex flex-col items-center justify-center gap-2 bg-primary text-white px-6 md:px-2 py-8 md:w-[85px] h-[116px] border-l border-primary"
              onClick={() => setIsOpen(false)}
            >
              <XIcon
                className="h-5 w-5 group-hover:animate-pulse"
                aria-hidden="true"
              />
              <span className="block sr-only md:not-sr-only uppercase font-important font-medium text-xs tracking-wider">
                {t("utils.close")}
              </span>
            </button>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
}
