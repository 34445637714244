import React from "react";
import { Combobox } from "@headlessui/react";
import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";

export const SEARCH_TYPES = {
  "node--news": {
    title: "Actualité",
    class: "text-primary",
  },
  "node--event": {
    title: "Évènement",
    class: "text-tertiary",
  },
  "node--page": {
    title: "Rubrique",
    class: "text-gray-400",
  },
  "node--gallery": {
    title: "Album photo",
    class: "text-secondary",
  },
};

export default function SearchResults({ results }) {
  const { t } = useTranslation("common");

  return (
    <Combobox.Options className="relative py-4">
      {results.length > 0 ? (
        <>
          {results.map((result) => {
            return (
              <Combobox.Option
                key={result.id}
                value={result.path.alias}
                className={({ active }) =>
                  classNames(
                    active && "text-primary",
                    "flex items-center gap-1 py-4 cursor-pointer font-important transition-colors"
                  )
                }
              >
                <span className="flex-1">{result.title}</span>
                <span
                  className={classNames(
                    "text-xs uppercase font-important font-medium tracking-wider",
                    SEARCH_TYPES[result.type].class
                  )}
                >
                  {SEARCH_TYPES[result.type].title}
                </span>
              </Combobox.Option>
            );
          })}
        </>
      ) : (
        <p className="py-4">{t("utils.empty")}</p>
      )}
    </Combobox.Options>
  );
}
