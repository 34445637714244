import React from "react";
import Image from "next/image";

export default function NodeBannerList({ nodes }) {
  const firstActiveBanner = nodes[0];

  return (
    <section className="relative overflow-hidden">
      <ul>
        <li
          key={firstActiveBanner.id}
          className="relative aspect-w-5 aspect-h-7 md:aspect-w-16 md:aspect-h-10 lg:aspect-h-5"
        >
          <figure>
            <Image
              src={firstActiveBanner.field_image.image_style_uri.hq}
              {...firstActiveBanner.field_image.resourceIdObjMeta}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
            <figcaption className="sr-only">
              {firstActiveBanner.title}
            </figcaption>
          </figure>
        </li>
      </ul>
    </section>
  );
}
