import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import Link from "next/link";
import React from "react";

export default function LinksBlock({ data, showTitle = false }) {
  return (
    <div>
      <h2 className={showTitle ? "" : "sr-only"}>{data.info}</h2>
      <ul className="flex flex-col gap-8">
        {data.field_links.map((link) => {
          const [type, value] = link.uri.split(":");
          const hasType = typeof value !== undefined;

          return (
            <li key={link.id}>
              <Link href={link.url} passHref>
                <a className="flex items-center gap-2">
                  {hasType && type === "tel" && (
                    <PhoneIcon className="h-5 w-5 text-primary" />
                  )}
                  {hasType &&
                    (type === "mailto" ||
                      (type === "entity" && value === "node/228")) && (
                      <MailIcon className="h-5 w-5 text-primary" />
                    )}
                  <span className="font-semibold underline underline-offset-4 decoration-primary hover:text-primary transition-colors">
                    {link.title}
                  </span>
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
