import React, { useState } from "react";
import Link from "next/link";
import { Menu } from "@headlessui/react";
import HeroIcon from "components/HeroIcon";
import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";

export default function MainMenu({ menu, closeCallback = () => null }) {
  const { t } = useTranslation("common");

  return (
    menu && (
      <>
        <MainMenuItems menu={menu} closeCallback={closeCallback} />

        <ul className="flex xl:hidden flex-col gap-4 mt-6">
          <li>
            <Link href={t("news.path")} passHref>
              <a
                className="font-important font-bold text-white"
                onClick={closeCallback}
              >
                {t("news.title")}
              </a>
            </Link>
          </li>
          <li>
            <Link href={t("event.path")} passHref>
              <a
                className="font-important font-bold text-white"
                onClick={closeCallback}
              >
                {t("event.title")}
              </a>
            </Link>
          </li>
        </ul>
      </>
    )
  );
}

function MainMenuItems({ menu, closeCallback }) {
  const [active, setActive] = useState();

  const variants = {
    primary: {
      icon: "text-primary",
    },
    secondary: {
      icon: "text-secondary",
    },
    tertiary: {
      icon: "text-tertiary",
    },
  };

  return (
    <ul className="flex flex-col gap-6 xl:gap-0 xl:flex-row xl:divide-x xl:h-full">
      {menu.map((link, id) => {
        const hasDropdown = link.items && link.items.length > 0;
        const hasHeroIcon =
          link.options.length > 0
            ? link.options.attributes["data-icon-name"]
            : false;
        const theme =
          link.options.length > 0
            ? link.options.attributes["data-theme"]
            : "primary";

        if (link.id === "standard.front_page") {
          return (
            <li key={id} className="relative h-full">
              <Link href="/" passHref>
                <a
                  className="px-12 py-4 flex items-center gap-4 h-full underline underline-offset-4 decoration-transparent transition-colors hover:bg-gray-100"
                  onClick={closeCallback}
                >
                  <span className="font-semibold">{link.title}</span>
                </a>
              </Link>
            </li>
          );
        }

        return (
          <li key={id} className="relative h-full">
            {hasDropdown ? (
              <MainMenuItemDropdown
                item={link}
                active={active}
                activeCallback={setActive}
                closeCallback={closeCallback}
              />
            ) : (
              <Link href={link.url} passHref>
                <a
                  className="px-12 py-4 flex items-center gap-4 h-full underline underline-offset-4 decoration-transparent transition-colors hover:bg-gray-100"
                  onClick={closeCallback}
                >
                  {hasHeroIcon && (
                    <HeroIcon
                      name={link.options.attributes["data-icon-name"]}
                      type={link.options.attributes["data-icon-type"]}
                      className={classNames(
                        "w-5 h-5 2xl:w-6 2xl:h-6",
                        variants[theme].icon
                      )}
                    />
                  )}
                  <span className="font-semibold">{link.title}</span>
                </a>
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
}

function MainMenuItemDropdown({ item, active, activeCallback, closeCallback }) {
  const isActive = active && active.id === item.id;
  const hasHeroIcon = item.options?.attributes["data-icon-name"] || false;
  const theme = item.options?.attributes["data-theme"] || "primary";

  const variants = {
    primary: {
      icon: "text-primary",
      submenu: "xl:before:text-primary xl:hover:text-primary",
    },
    secondary: {
      icon: "text-secondary",
      submenu: "xl:before:text-secondary xl:hover:text-secondary",
    },
    tertiary: {
      icon: "text-tertiary",
      submenu: "xl:before:text-tertiary xl:hover:text-tertiary",
    },
  };

  return (
    <Menu>
      {({ open }) => (
        <>
          <Menu.Button
            className={classNames(
              "flex items-center gap-4 text-white text-xl font-important transition-colors",
              "xl:px-8 2xl:px-12 xl:py-4 xl:h-full xl:text-default xl:text-base xl:font-sans xl:hover:bg-gray-100",
              open && "xl:bg-gray-100"
            )}
            onClick={() => activeCallback(isActive ? null : { id: item.id })}
          >
            {hasHeroIcon && (
              <HeroIcon
                name={item.options.attributes["data-icon-name"]}
                type={item.options.attributes["data-icon-type"]}
                className={classNames(
                  "w-5 h-5 2xl:w-6 2xl:h-6",
                  "hidden xl:block",
                  variants[theme].icon
                )}
              />
            )}
            <span className="font-bold xl:font-semibold">{item.title}</span>
          </Menu.Button>
          {isActive && (
            <Menu.Items
              className={classNames(
                "flex flex-col mt-4 xl:mt-0",
                "xl:absolute xl:bg-white xl:right-0 xl:w-96 xl:p-8 xl:shadow-spread"
              )}
              static
            >
              {item.items.map((item, index) => {
                return (
                  <Menu.Item key={index}>
                    <Link href={item.url} passHref>
                      <a
                        className={classNames(
                          "text-white xl:text-default uppercase font-important font-medium tracking-wider text-sm py-2 before:content-['///'] before:mr-2 transition-colors",
                          variants[theme].submenu
                        )}
                        onClick={() => {
                          activeCallback(null);
                          closeCallback();
                        }}
                      >
                        {item.title}
                      </a>
                    </Link>
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          )}
        </>
      )}
    </Menu>
  );
}
