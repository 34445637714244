import Body from "components/Body";
import React from "react";

export default function BasicBlock({ data, showTitle = false }) {
  return (
    <div>
      <h2 className={showTitle ? "" : "sr-only"}>{data.info}</h2>
      <div className="prose max-w-none text-sm">
        <Body value={data.body.value} />
      </div>
    </div>
  );
}
