import React, { createRef, useState } from "react";
import PreviewAlert from "components/PreviewAlert";
import NodeBannerList from "components/Banner/NodeBannerList";
import Branding from "components/Branding";
import useTranslation from "next-translate/useTranslation";
import SearchForm from "components/Search/SearchForm";
import QuickAccessMenu from "components/Menu/QuickAccessMenu";
import FlashInfo from "components/FlashInfo";
import Sidebar from "components/Sidebar";
import SearchBar from "components/Search/SearchBar";
import Navbar from "components/Navbar";
import { SearchIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";
import IsicsLogo from "components/IsicsLogo";
import { SidebarFooter } from "components/Sidebar";
import BasicBlock from "components/Block/BasicBlock";
import LinksBlock from "components/Block/LinksBlock";
import FooterMenu from "components/Menu/FooterMenu";
import Pattern7 from "components/Patterns/Pattern7";

export default function Layout({
  children,
  globals,
  banners,
  header = null,
  home = false,
}) {
  const topRef = createRef();
  const mainRef = createRef();
  const { t } = useTranslation("common");
  const [showSearch, setShowSearch] = useState(false);

  return (
    <>
      <PreviewAlert />

      {globals?.info && <FlashInfo node={globals.info} />}

      <SearchBar
        show={showSearch}
        close={() => setShowSearch(false)}
        autoFocus
      />

      <header ref={topRef} className="relative z-10 bg-white">
        <div className="relative z-20 xl:z-10">
          <div className="container px-4 mx-auto z-10">
            <div className="flex xl:divide-x pr-[85px]">
              <div className="flex-1 py-4">
                <Branding home={home} />
              </div>
              <div className="flex xl:hidden items-center justify-center flex-initial">
                <button
                  className={classNames(
                    "flex items-center justify-center p-4 rounded-full text-primary bg-primary-light hover:bg-primary hover:text-white transition-colors",
                    showSearch && "bg-primary text-white"
                  )}
                  onClick={() => setShowSearch((showSearch) => !showSearch)}
                >
                  <SearchIcon className="h-4 w-4" aria-hidden="true" />
                  <span className="sr-only">{t("search.title")}</span>
                </button>
              </div>
              <div className="flex-initial">
                <Navbar globals={globals} />
              </div>
            </div>
          </div>
        </div>

        <Sidebar
          mainRef={mainRef}
          searchStatus={showSearch}
          searchCallback={() => setShowSearch((showSearch) => !showSearch)}
          menuCallback={() => setShowMenu((showMenu) => !showMenu)}
        />

        {banners?.length > 0 && (
          <div className="relative">
            <NodeBannerList nodes={banners} />

            <div className="absolute inset-x-0 bottom-0 xl:bottom-28 py-8">
              <div className="container mx-auto px-4">
                <div className="max-w-3xl">
                  <SearchForm closeSearch={() => undefined} />
                  <QuickAccessMenu menu={globals.menus.quickAccess} />
                </div>
              </div>
            </div>
          </div>
        )}

        {header}
      </header>

      <main ref={mainRef}>{children}</main>

      <footer className="relative border-t">
        <div className="relative pt-16 pb-8">
          <div
            className="hidden lg:block absolute left-0 bottom-0"
            aria-hidden="true"
          >
            <Pattern7 className="text-primary text-opacity-30" />
          </div>

          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 lg:pr-[85px]">
              <div>
                <Branding small />
              </div>

              {globals?.blocks?.infos && (
                <BasicBlock data={globals.blocks.infos} />
              )}

              {globals?.blocks?.links && (
                <LinksBlock data={globals.blocks.links} />
              )}

              <div className="flex flex-col gap-8">
                {globals?.menus?.footer && (
                  <FooterMenu menu={globals.menus.footer} />
                )}
              </div>
            </div>
            <div className="mt-16">
              <a
                href="https://www.isics.fr"
                target="blank"
                className="flex items-center justify-center font-semibold text-sm text-default gap-2"
              >
                <span>{t("copyright.text")}</span>
                <IsicsLogo className="h-3" />
              </a>
            </div>
          </div>
        </div>

        <SidebarFooter topRef={topRef} />
      </footer>
    </>
  );
}
