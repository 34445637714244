import React from "react";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import IconFacebook from "components/Icons/IconFacebook";
import IconInstagram from "components/Icons/IconInstagram";
import IconTwitter from "components/Icons/IconTwitter";
import classNames from "lib/classNames";
import Link from "next/link";
import getSocialFromUrl from "lib/getSocialFromUrl";
import HeroIcon from "components/HeroIcon";

export default function SocialNetworksMenu({ menu }) {
  const components = {
    facebook: IconFacebook,
    instagram: IconInstagram,
    twitter: IconTwitter,
    other: ExternalLinkIcon,
  };

  return (
    <ul className="absolute top-0 md:left-24 flex items-center gap-4 py-8 xl:static xl:gap-8 xl:py-0 xl:px-8 xl:h-full">
      {menu.map((link) => {
        const hasHeroIcon = link.options?.attributes["data-icon-name"] || false;
        const social = getSocialFromUrl(link.url);
        const IconComponent = components[social];

        return (
          <li key={link.title}>
            <Link href={link.url} passHref>
              <a
                className="flex items-center justify-center p-4 rounded-full text-primary bg-primary-light hover:bg-primary hover:text-white transition-colors"
                target={link.options.attributes.target}
              >
                {hasHeroIcon ? (
                  <HeroIcon
                    name={link.options.attributes["data-icon-name"]}
                    type={link.options.attributes["data-icon-type"]}
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                  />
                ) : (
                  <IconComponent className="w-4 h-4 inline-block" />
                )}
                <span
                  className={classNames(
                    hasHeroIcon || social !== "other" ? "sr-only" : "ml-2"
                  )}
                >
                  {link.title}
                </span>
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
