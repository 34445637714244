import React, { createRef, useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { SearchIcon } from "@heroicons/react/outline";
import SearchResults from "components/Search/SearchResults";
import useSearch from "hook/useSearch";

export default function SearchForm({ closeSearch, autoFocus = false }) {
  const { t } = useTranslation("common");
  const [selected, setSelected] = useState(null);
  const { state, callSearch, cancelSearch, resetSearch } = useSearch();
  const router = useRouter();
  const searchRef = createRef();

  useEffect(() => {
    if (autoFocus && searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  useEffect(() => {
    if (selected !== null) {
      cancelSearch();
      resetSearch();
      setSelected(null);
      router.push(selected);
    }
  }, [selected]);

  return (
    <Combobox value={null} onChange={setSelected}>
      <div className="relative mb-6">
        <div className="relative bg-white rounded-full overflow-hidden">
          <SearchIcon
            className="absolute right-8 lg:right-16 top-1/2 -translate-y-1/2 h-5 w-5 text-primary"
            aria-hidden="true"
          />
          <Combobox.Input
            ref={searchRef}
            onChange={(event) => callSearch(event.target.value)}
            placeholder={t("search.placeholder")}
            className="relative pl-8 lg:pl-16 pr-16 lg:pr-24 py-6 lg:py-8 w-full appearance-none bg-transparent shadow-spread focus:outline-none font-important font-semibold placeholder:text-default"
            autoComplete="off"
          />
        </div>

        <div className="absolute w-full z-30 mt-4 px-8 bg-white shadow-spread rounded-3xl">
          <SearchResults results={state.results} />
        </div>
      </div>
    </Combobox>
  );
}
