import React from "react";

export default function Pattern2({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="52"
      height="202"
      viewBox="0 0 52 202"
      className={className}
    >
      <defs>
        <rect id="pattern2rect" width="201" height="51" x="0" y="0" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="rotate(-90 101 100.5)">
        <mask id="pattern2mask" fill="#fff">
          <use xlinkHref="#pattern2rect" />
        </mask>
        <path
          fill="currentColor"
          d="M-623.401697,-639 L-631,-631.401721 L56.0843977,51 L59,48.0844071 L-623.401697,-639 Z M-592.61885,-639 L-600,-631.621068 L87.3052593,51 L90,48.3052593 L-592.61885,-639 Z M-562.801015,-639 L-570,-631.801015 L117.490893,51 L120,48.490893 L-562.801015,-639 Z M-533.023018,-639 L-540,-632.020799 L147.674339,51 L150,48.6765585 L-533.023018,-639 Z M-502.250335,-638 L-509,-631.250357 L177.898627,51 L180,48.8964167 L-502.250335,-638 Z M-472.434611,-638 L-479,-631.434611 L208.117523,51 L210,49.1175227 L-472.434611,-638 Z M-441.616132,-638 L-448,-631.620566 L216.155131,28 L218,26.1551308 L-441.616132,-638 Z"
          mask="url(#pattern2mask)"
        />
      </g>
    </svg>
  );
}
