import React from "react";

export default function Pattern7({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="157"
      height="254"
      viewBox="0 0 157 254"
      className={className}
    >
      <defs>
        <rect id="pattern7rect" width="157" height="254" x="0" y="0" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 157 0)">
        <mask id="pattern7mask" fill="#fff">
          <use xlinkHref="#pattern7rect" />
        </mask>
        <path
          fill="currentColor"
          d="M449.086527,-69 L454,-64.0865272 L1.84727038,385 L0,383.15273 L449.086527,-69 Z M418.279775,-68 L423,-63.279775 L1.84463465,355 L0,353.155365 L418.279775,-68 Z M388.495003,-69 L393,-64.4928088 L1.84638319,324 L0,322.153627 L388.495003,-69 Z M357.726215,-68 L362,-63.726215 L1.84333286,294 L0,292.156667 L357.726215,-68 Z M327.905999,-68 L332,-63.9038083 L1.8451837,264 L0,262.154829 L327.905999,-68 Z M298.120718,-68 L302,-64.1207179 L1.84748866,234 L0,232.152511 L298.120718,-68 Z M267.350594,-68 L271,-64.3505936 L1.84353741,203 L0,201.156463 L267.350594,-68 Z M237.529692,-68 L241,-64.5319112 L1.84609719,173 L0,171.151684 L237.529692,-68 Z"
          mask="url(#pattern7mask)"
        />
      </g>
    </svg>
  );
}
