import React, { useEffect, useState } from "react";
import Body from "components/Body";
import useTranslation from "next-translate/useTranslation";
import { Transition } from "@headlessui/react";
import Cookies from "universal-cookie";

export default function FlashInfo({ node }) {
  const { t } = useTranslation("common");
  const [show, setShow] = useState(false);
  const cookies = new Cookies();

  const discard = () => {
    const closedId = cookies.get("flash-closed");

    if (show && (closedId === undefined || closedId !== node.id)) {
      cookies.set("flash-closed", node.id, { path: "/" });
    }

    setShow(false);
  };

  useEffect(() => {
    const closedId = cookies.get("flash-closed");

    if (closedId !== node.id) {
      setShow(true);
    }
  }, [setShow]);

  return (
    <Transition
      appear={true}
      show={show}
      className="relative bg-secondary-light py-4 border-b overflow-hidden z-30"
      enter="transition-all duration-500"
      enterFrom="opacity-0 max-h-0"
      enterTo="opacity-100 max-h-96"
      leave="transition-all duration-500"
      leaveFrom="opacity-100 max-h-96"
      leaveTo="opacity-0 max-h-0"
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row lg:items-center gap-4 lg:gap-8">
          <div className="flex-initial">
            <span className="font-important font-medium uppercase tracking-wide bg-secondary px-2 py-1 rounded-md text-xs text-white">
              Info
            </span>
          </div>
          <div className="flex-1 prose max-w-none text-sm font-semibold">
            <Body value={node.body?.value} />
          </div>
          <div className="flex-initial">
            <button
              className="underline underline-offset-4 decoration-primary font-semibold text-sm hover:text-primary transition-colors"
              onClick={() => discard()}
            >
              {t("utils.close")}
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
}
