import React from "react";
import Link from "next/link";

export default function FooterMenu({ menu }) {
  return (
    menu && (
      <ul className="flex flex-col gap-4 text-sm">
        {menu.map((item) => {
          return (
            <li key={item.id}>
              <Link href={item.url} passHref>
                <a className="underline underline-offset-4 text-default text-opacity-50 hover:text-opacity-100 transition-colors">
                  {item.title}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    )
  );
}
