import React from "react";
import parse, { domToReact, Element } from "html-react-parser";
import Image from "next/image";
import Link from "next/link";
import Pattern2 from "components/Patterns/Pattern2";
import classNames from "lib/classNames";

const options = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.name === "img") {
      const { src, alt, width, height } = domNode.attribs;

      return (
        <Image
          src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${src}`}
          width={`${width}px`}
          height={`${height}px`}
          alt={alt}
          layout="intrinsic"
          objectFit="cover"
        />
      );
    }

    if (domNode.name === "blockquote") {
      const { class: className } = domNode.attribs;

      return (
        <blockquote className={classNames("relative", className)}>
          <div
            className="hidden lg:block absolute top-0 left-0"
            aria-hidden="true"
          >
            <Pattern2 className="text-secondary" />
          </div>

          {domToReact(domNode.children)}
        </blockquote>
      );
    }

    if (domNode.name === "a") {
      const { href, class: className, target } = domNode.attribs;

      return (
        <Link href={href} passHref>
          <a className={className} target={target}>
            {domToReact(domNode.children)}
          </a>
        </Link>
      );
    }

    return domNode;
  },
};

export default function Body({ value }) {
  return <>{parse(value, options)}</>;
}
