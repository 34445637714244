import React from "react";
import Link from "next/link";

export default function QuickAccessMenu({ menu }) {
  return (
    <ul className="relative flex flex-wrap items-center gap-2.5">
      {menu.map((link) => (
        <li key={link.id}>
          <Link href={link.url} passHref>
            <a className="block py-2.5 px-8 bg-gray-800 text-white font-important text-sm rounded-lg hover:bg-gray-700 transition-colors">
              {link.title}
            </a>
          </Link>
        </li>
      ))}
    </ul>
  );
}
