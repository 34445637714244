import React, { createRef, useEffect, useRef, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import SearchResults from "components/Search/SearchResults";
import useSearch from "hook/useSearch";
import { SEARCH_STATUS } from "reducer/searchReducer";

export default function SearchBar({ show, close, autoFocus = false }) {
  const { t } = useTranslation("common");
  const { state, callSearch, cancelSearch, resetSearch } = useSearch();
  const [selected, setSelected] = useState(null);
  const router = useRouter();
  const searchRef = useRef();

  const focus = () => {
    if (autoFocus && searchRef.current) {
      searchRef.current.focus();
    }
  };

  useEffect(() => {
    if (selected !== null) {
      close();
      cancelSearch();
      resetSearch();
      setSelected(null);
      router.push(selected);
    }
  }, [selected, close]);

  return (
    <Transition
      show={show}
      className="relative z-20"
      enter="transition-all duration-500"
      enterFrom="opacity-0 max-h-0"
      enterTo="opacity-100 max-h-96"
      leave="transition-all duration-500"
      leaveFrom="opacity-100 max-h-96"
      leaveTo="opacity-0 max-h-0"
      afterEnter={() => focus()}
    >
      <Combobox value={null} onChange={setSelected}>
        <div className="relative bg-primary-light border-b z-20">
          <div className="container mx-auto px-4">
            <div className="relative">
              <div className="relative overflow-hidden z-20">
                <span
                  className="absolute right-0 top-1/2 -translate-y-1/2 h-12 w-12 bg-primary text-white text-sm uppercase font-important font-medium tracking-wider rounded-full"
                  aria-hidden="true"
                >
                  <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    OK
                  </span>
                  {state.status === SEARCH_STATUS.LOADING && (
                    <span className="absolute inset-0 border-2 border-white/20 border-r-white/70 animate-spin rounded-full"></span>
                  )}
                </span>
                <Combobox.Input
                  ref={searchRef}
                  onChange={(event) => callSearch(event.target.value)}
                  placeholder={t("search.placeholder")}
                  className="relative pl-0 pr-20 py-8 w-full appearance-none bg-transparent shadow-spread focus:outline-none text-important text-semibold placeholder:text-default"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </div>
        <Transition
          show={state.results.length > 0}
          className="absolute inset-x-0 bg-primary-light shadow-spread border-b z-10"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div className="container mx-auto px-4">
            <SearchResults results={state.results} />
          </div>
        </Transition>
      </Combobox>
    </Transition>
  );
}
