import React from "react";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import classNames from "lib/classNames";

export default function BrandingContent({ home = false, small = false }) {
  const { t } = useTranslation("common");
  const TitleTag = home ? "h1" : "div";

  return (
    <div className="inline-flex items-center space-x-4">
      <div
        className={classNames(
          "relative flex-initial w-[57px] h-[84px]",
          !small && "xl:w-[81px] xl:h-[120px]"
        )}
      >
        <Image
          src="/images/blason.png"
          width={small ? 57 : 81}
          height={small ? 84 : 120}
          layout="fill"
          alt={t("branding.alt")}
        />
      </div>
      <TitleTag
        className={classNames("flex-1 uppercase", !small && "hidden sm:block")}
      >
        <span className="font-semibold text-sm text-gray-400">
          {t("branding.title.part1")}
        </span>
        <span
          className={classNames(
            "block text-lg font-important font-bold tracking-wider whitespace-nowrap",
            !small && "xl:text-xl"
          )}
        >
          {t("branding.title.part2")}
        </span>
      </TitleTag>
    </div>
  );
}
