import React from "react";
import * as OutlineIcons from "@heroicons/react/outline";
import * as SolidIcons from "@heroicons/react/solid";

export default function HeroIcon({ name, type = "outline", className }) {
  const fragments = name.split("-");
  const joinded = fragments
    .map((fragment) => {
      return fragment[0].toUpperCase() + fragment.slice(1);
    })
    .join("");
  const iconName = `${joinded}Icon`;

  const DynamicComponent =
    type === "outline" ? OutlineIcons[iconName] : SolidIcons[iconName];

  return <DynamicComponent className={className} />;
}
